import React from "react";

export const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
      <div className="container-fluid">
        <span className="navbar-brand">Amplifier SSO Install</span>
        <div className="collapse navbar-collapse" id="navbarColor01">
          <ul className="navbar-nav me-auto"></ul>
        </div>
      </div>
    </nav>
  );
};
