import { z } from "zod";
import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";

import SweetAlert2 from "react-sweetalert2";
import { INSTALL_API_URL_MAP, WEB_REDIRECT_MAP } from "./constants";
import moment from "moment";

const parseToken = (token: string) => {
  const parts = token.split(".");
  const raw = atob(parts[1]);
  return z
    .object({
      slug: z.string(),
      exp: z.number(),
    })
    .parse(JSON.parse(raw));
};

export const OktaForm = () => {
  // TODO : token
  const [loading, setLoading] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const { env, token } = z
    .object({
      env: z.enum(["local", "qa", "prod"]),
      token: z.string(),
    })
    .parse(useParams());
  const API_BASE_URL = INSTALL_API_URL_MAP[env];
  const REDIRECT_URL = WEB_REDIRECT_MAP[env];
  const [domain, setDomain] = useState("");
  const [loginUrl, setLoginUrl] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  if (!token || !env) {
    return (
      <div className="alert alert-danger">
        <h1>Invalid Install URL</h1>
      </div>
    );
  }
  const { slug, exp } = parseToken(token);
  const valid = domain.length && clientID.length && clientSecret.length;
  const expM = moment(exp * 1000);
  const install = async () => {
    if (!valid || loading) return;
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/install/sso/okta`,
        {
          domain,
          clientID,
          clientSecret,
          token,
        }
      );
      const res = z
        .object({
          success: z.boolean(),
          domain: z.string(),
          slug: z.string(),
          installerEmail: z.string(),
        })
        .parse(response.data);
      if (res.success) {
        setLoginUrl(
          `${API_BASE_URL}/api/v1/auth/okta/${
            res.slug
          }/login?redirectUrl=${encodeURIComponent(REDIRECT_URL)}`
        );
        setSwalProps({
          show: true,
          title: "Okta SSO Installed",
          text: `SSO Installed by ${res.installerEmail} for ${res.domain}`,
          icon: "success",
        });
      }
      console.log(response);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container pt-5">
      <div className="content">
        <div className="form-group mb-4">
          <label>Okta Domain</label>
          <input
            type="text"
            className="form-control"
            placeholder="Domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
        </div>
        <div className="form-group mb-4">
          <label>Okta Client ID</label>
          <input
            type="text"
            className="form-control"
            placeholder="Client ID"
            value={clientID}
            onChange={(e) => setClientID(e.target.value)}
          />
        </div>
        <div className="form-group mb-4">
          <label>Okta Client Secret</label>
          <input
            type="password"
            className="form-control"
            placeholder="Client Secret"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
          />
        </div>
        <div className="form-group">
          <button
            className="btn btn-primary"
            disabled={!valid || loading}
            onClick={install}
          >
            {valid ? "Install" : "Enter Valid Credentials"}
          </button>
        </div>
      </div>
      <SweetAlert2
        {...swalProps}
        onConfirm={() => {
          window.location.href = loginUrl;
        }}
      />
      <div className="py-5">
        <OktaSetupInstructions slug={slug} exp={exp} />
      </div>
      <div className="py-5"> </div>
    </div>
  );
};

const OktaSetupInstructions = ({
  slug,
  exp,
}: {
  slug: string;
  exp: number;
}) => {
  const expM = moment(exp * 1000);
  return (
    <div className="mt-5">
      <h1>Okta SSO Install Instructions</h1>
      <p>
        <b>Method:</b> {mono("OAuth SSO")}
      </p>
      <p>
        <b>Estimated Time to Complete:</b> {mono("10-20 minutes")}
      </p>
      <p>
        <b>Installer:</b> {mono("Okta Administrator")}
      </p>
      <p>
        <b>Install Link:</b> expires {expM.fromNow()}
      </p>
      <h2>Create An Application</h2>
      <ol>
        <li>
          Navigate to Okta Admin page then {mono("Applications")} =&gt;
          {mono("Applications")}
        </li>
        <li>Click {mono("Create App Integration")}</li>
        <li>
          Select {mono("OIDC - OpenID Connect")} and click{" "}
          {mono("Web Application")}
        </li>
        <li>
          Enter the following Values{" "}
          <ol>
            <li>
              App Integration Name: <CopyText text="Amplifier Security Hub" />
            </li>
            <li>Logo - See image below</li>
            <li>Grant Type: {mono("Authorization Code")}</li>
            <li>
              Sign-in redirect URIs{" "}
              <ol>
                <li>
                  <CopyText
                    text={`https://api.amplifiersecurity.io/api/v1/auth/okta/${slug}/callback`}
                  />
                </li>
              </ol>
            </li>

            <li>Delete the {mono("Sign-out redirect URIs")} entry</li>
            <li>Ignore {mono("Trusted Origins")} section</li>
            <li>Complete {mono("Assignments")}</li>
          </ol>
        </li>

        <li>Click {mono("Save")}</li>
      </ol>
      <h2>Setup OAuth Contract</h2>
      <ol>
        <li>
          Click {mono("Edit")} on {mono("General Settings")} section and enter
          the following:
          <ol>
            <li>
              User Conset{" "}
              <ol>
                <li>
                  Terms of Service URI:{" "}
                  <CopyText text="https://www.amplifiersecurity.com/tos" />
                </li>
                <li>
                  Privacy Policy URI:{" "}
                  <CopyText text="https://www.amplifiersecurity.com/privacy" />
                </li>
                <li>
                  Logo URI:{" "}
                  <CopyText text="https://framerusercontent.com/images/koZvHA7Y47r0jYLfovqpWrt24.png" />
                </li>
              </ol>
            </li>
            <li>
              Login{" "}
              <ol>
                <li>
                  Login initiated by: {mono("Either Okta or App Application")}
                </li>
                <li>Visibility: Check All</li>
                <li>Login Flow:</li>
                <li>
                  Redirect to app to initiate login Initiate Login URI:
                  <CopyText
                    text={`https://api.amplifiersecurity.io/api/v1/auth/okta/${slug}/login?redirectUrl=https%3A%2F%2Fapp.amplifiersecurity.io%2Fauth%2Fredirect`}
                  />
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Click on the {mono("Okta API Scopes")} tab and {mono("Grant")} the
          following
          <ol>
            <li>
              <CopyText text="okta.myAccount.profile.read" />
            </li>
            <li>
              <CopyText text="okta.myAccount.email.read" />
            </li>
          </ol>
        </li>
      </ol>
      <h2>Submit Okta SSO Credentails</h2>
      <ol>
        <li>
          Gather the required OAuth Client Credentials
          <ol>
            <li>Copy the “Client Credentials” =&gt; “Client ID”</li>
            <li>Copy the “Client Secrets” =&gt; “Secret”</li>
            <li>
              Copy the domain from your admin profile dropdown. See image below
            </li>
          </ol>
        </li>
        <li>Enter the values into the form above</li>
      </ol>
      <div>
        <em>
          <b>Note:</b> the installation link will expire {expM.fromNow()}.
          Please contact the amplifier team if you need another installation
          link.
        </em>
      </div>
      <div>
        <em>
          <b>Note:</b> The installation link has an embedded installation token.
          Consider this link as a sensitive value as it can override SSO into
          your tenant. You should not share it with others during the setup
          process. All SSO flows will be initiated from the Okta tiles. This
          link is ONLY for installation/setup. Let the Amplifier team know if
          you would prefer a link with a shorter expiration, e.g. 1 hour.
        </em>
      </div>
      <h2>Assets</h2>
      <h3>Amplifier Logo (png)</h3>
      <div className="my-3">
        <img
          src="/amplifierSecurity-logo.png"
          alt="Amplifier Logo"
          className="img-fluid"
        />
      </div>
      <h3>Copy Okta Domain Screenshot</h3>
      <div className="my-3">
        <img
          src="/copyOktaDomain.png"
          alt="Okta Domain"
          className="img-fluid"
        />
      </div>
    </div>
  );
};

const mono = (text: string) => <span className="font-monospace">"{text}"</span>;

const CopyText = ({ text }: { text: string }) => {
  return (
    <>
      <code className="me-3">{text}</code>
      <CopyButton text={text} />
    </>
  );
};

const CopyButton = ({ text }: { text: string }) => {
  const [copied, setCopied] = useState(false);
  return (
    <button
      className={`btn btn-sm btn-${copied ? "success" : "secondary"}`}
      onClick={() => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      }}
    >
      {copied ? "Copied!" : "Copy"}
    </button>
  );
};
