import { z } from "zod";
import axios from "axios";
import React from "react";
import { INSTALL_API_URL_MAP } from "./constants";

export const OktaSetupForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [platformToken, setPlatformToken] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [installerEmail, setInstallerEmail] = React.useState("");
  const [env, setEnv] = React.useState("");
  const [ssoToken, setSsoToken] = React.useState("");
  const valid =
    platformToken.length &&
    slug.length &&
    installerEmail.length &&
    (env === "local" || env === "qa" || env === "prod");
  const installLink = valid
    ? `https://install.amplifiersecurity.io/${env}/sso/okta/${ssoToken}`
    : "";
  return (
    <div>
      <div className="form-group mb-4">
        <textarea
          name=""
          id=""
          rows={3}
          className="form-control"
          placeholder="token"
          value={platformToken}
          onChange={(e) => setPlatformToken(e.target.value)}
        ></textarea>
      </div>
      <div className="form-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="slug"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />
      </div>
      <div className="form-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="installer email"
          value={installerEmail}
          onChange={(e) => setInstallerEmail(e.target.value)}
        />
      </div>
      <div className="form-group mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="env"
          value={env}
          onChange={(e) => setEnv(e.target.value)}
        />
      </div>
      <div className="form-group mb-4">
        <button
          className="btn btn-primary"
          disabled={!valid || loading}
          onClick={async () => {
            try {
              setLoading(true);
              const BASE_URL =
                INSTALL_API_URL_MAP[env as keyof typeof INSTALL_API_URL_MAP];
              const response = await axios({
                method: "POST",
                url: `${BASE_URL}/api/v1/install/sso/okta/token`,
                data: {
                  token: platformToken,
                  slug,
                  installerEmail,
                },
                headers: {
                  Authorization: `Bearer ${platformToken}`,
                },
              });
              const res = z
                .object({
                  token: z.string(),
                })
                .parse(response.data);
              setSsoToken(res.token);
            } catch (error) {
            } finally {
              setLoading(false);
            }
          }}
        >
          Create
        </button>
      </div>
      {ssoToken && (
        <div className="alert alert-info hyphenate">
          <h3>Install Link</h3>
          <a href={installLink} target="_blank" rel="noreferrer">
            {installLink}
          </a>
          <h3>Token</h3>
          <code>{ssoToken}</code>
        </div>
      )}
    </div>
  );
};
