export const INSTALL_API_URL_MAP = {
  local: "http://localhost:8080",
  qa: "https://api.ampsec.xyz",
  prod: "https://api.amplifiersecurity.io",
} as const;

export const WEB_REDIRECT_MAP = {
  local: "http://localhost:3042/auth/redirect",
  qa: "https://app.ampsec.xyz/auth/redirect",
  prod: "https://app.amplifiersecurity.io/auth/redirect",
} as const;
