import { Route, Routes } from "react-router-dom";
import { OktaForm } from "./OktaForm";
import { Header } from "./Header";
import { OktaSetupForm } from "./OktaSetupForm";

const App = () => {
  return (
    <div className="text-bg-dark" style={{ minHeight: "100vh" }}>
      <Header />
      <div className="container mt-5">
        <Routes>
          <Route path="/:env/sso/okta/:token" element={<OktaForm />} />
          <Route path="/sso/okta/setup" element={<OktaSetupForm />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
